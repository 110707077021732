import { ApiResponse } from '../models/ApiResponse';
import FileUploadResponse from '../models/FileUploadResponse';
import BaseService from './BaseService';

class FileService extends BaseService {
  public static MAX_SIZE = 100 * 1024 * 1024;

  public static uploadFile(file: File | File[], progresCb: (progress: number) => void): Promise<ApiResponse<FileUploadResponse[]>> {
    return this.postFile('/v1/files', 'files', file, progresCb);
  }

  public static deleteFile(fileId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/files/${fileId}`);
  }

  public static async getFile(fileId: string): Promise<Blob> {
    const res = await this.get<Blob>(`/v1/files/${fileId}`, { responseType: 'arraybuffer' });

    if (res.status !== 200) {
      throw { ...res, messsage: `Could not load file ${fileId}.` };
    }

    const fileContent = res.data;
    const fileType = res.headers['content-type'];

    return new Blob([fileContent], { type: fileType });
  }
}

export default FileService;
